import React, { FC } from 'react'

interface ProductCardProps {
    title: string
    children: React.ReactNode
}

const ProductCard: FC<ProductCardProps> = ({ title, children }) => {
    return (
        <div className='bg-grayscale-450 rounded-xl h-72 xl:h-80 lg:h-65 w-51 lg:w-auto flex-none relative overflow-hidden snap-center mx-2 first:ml-0 last:mr-0 lg:mx-0'>
            <div className='pt-6 pl-7 pr-10 font-display text-h400 font-medium relative z-10'>{title}</div>
            {children}
        </div>
    )
}

export default ProductCard