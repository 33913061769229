export const STEPS = [
  {
    name: 'Заявка',
    description: 'Оставляете заявку на сайте или по телефону.'
  },
  {
    name: 'Оборудование',
    description: 'Выбираем оборудование и согласовываем спецификацию.'
  },
  {
    name: 'Одобрение',
    description: 'Собираем документы и подбираем выгодные условия.'
  },
  {
    name: 'Договор',
    description: 'Подписываем договор лизинга.'
  },
  {
    name: 'Поставка',
    description: 'Получаете оборудование и начинаете зарабатывать.'
  }
]
