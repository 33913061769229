import React, { FC } from 'react'

interface StepsProps {
    steps: { name: string, description: string }[]
}

const Steps: FC<StepsProps> = ({ steps }) => {
    return (
        <div className='relative'>
            <ol className='flex text-left lg:text-center overflow-x-auto scrollbar-hide snap-x snap-mandatory px-6 scroll-pl-6 lg:px-0'>
                {steps.map((s, i) =>
                    <li className='lg:flex-1 relative w-42 lg:w-auto flex-none box-content pr-6 lg:pr-0 snap-start' key={s.name}>
                        <div className='rounded-full flex items-center justify-center border-3 w-13 h-13 lg:mx-auto border-red-150 text-red-100 text-h200 font-medium mb-5 bg-white-0 relative z-10'>
                            {i + 1}
                        </div>
                        <div className='text-h500 lg:text-h400 font-medium mb-2 font-display lg:max-w-[240px] lg:mx-auto'>{s.name}</div>
                        <p className='text-p350 text-grayscale-150 lg:px-4 lg:max-w-[240px] lg:mx-auto'>{s.description}</p>
                        {i < steps.length - 1 &&
                            <div className='absolute top-6 w-full lg:left-1/2 translate-x-6 lg:translate-x-0'>
                                <hr className='text-red-100 border-dashed border-b-1 mx-10' />
                            </div>
                        }
                    </li>
                )}
            </ol>
        </div>
    )
}

export default Steps