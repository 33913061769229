import React, { FC } from 'react'
import ProductCard from '@components/main/Card'
import Image from 'next/image'

import coffee from 'public/graphics/main/cards/coffee.png'
import container from 'public/graphics/main/cards/container.png'
import forkLift from '@public/graphics/main/cards/forklift.png'
import mill from '@public/graphics/main/cards/mill.png'
import mri from 'public/graphics/main/cards/mri.png'
import printer from 'public/graphics/main/cards/printer.png'
import tire from 'public/graphics/main/cards/tire.png'
import treadmill from 'public/graphics/main/cards/treadmill.png'

const cards = [
  {
    title: 'Ресторанный бизнес',
    image: coffee
  },
  {
    title: 'Офисная техника',
    image: printer
  },
  {
    title: 'Производство',
    image: mill
  },
  {
    title: 'Фитнес',
    image: treadmill
  },
  {
    title: 'Медицина',
    image: mri
  },
  {
    title: 'Торгово-складское оборудование',
    image: forkLift
  },
  {
    title: 'Автомастерские и сервисы',
    image: tire
  },
  {
    title: 'Другое',
    image: container
  }
]

const ProductCards: FC = () => {
  return (
    <div className='flex w-full lg:grid lg:grid-cols-4 lg:gap-4 px-6 lg:px-0 pb-4 lg:pb-0 overflow-x-auto scrollbar-hide snap-x snap-mandatory scroll-px-6'>
      {cards.map((c) => (
        <ProductCard key={c.title} title={c.title}>
          <div className='absolute inset-0 pointer-events-none'>
            <div className='relative w-full h-full'>
              <Image quality={95} src={c.image} alt={c.title} />
            </div>
          </div>
        </ProductCard>
      ))}
    </div>
  )
}

export default ProductCards
