import React, { FC } from 'react'
import Head from 'next/head'
import { applicationFormOpen } from 'src/store'
import dynamic from 'next/dynamic'

const Footer = dynamic(() => import('./Footer'))
const Nav = dynamic(() => import('./Nav'))
const ModalForm = dynamic(() => import('./ModalForm'))

const Layout: FC<{ children: React.ReactNode }> = ({ children }) => {

  return (
    <>
      <Head>
        <title>Fera.ru - лизинг оборудования для малого и среднего бизнеса</title>
        <meta name='description' content='Fera – финтех проект, который позволяет малому и среднему бизнесу по всей России приобретать различное оборудование в лизинг. Оставьте заявку прямо сейчас!' />
        <link rel='icon' href='/favicon.ico' />
      </Head>
      <header className='sticky top-0 z-50'>
        <Nav />
      </header>
      {children}
      <Footer />
      <ModalForm />
    </>
  )
}

export default Layout