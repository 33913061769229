import React, { FC, useEffect, useState } from 'react'
import { Transition } from '@headlessui/react'
import c from 'clsx'

const r1 = 669.5
const r2 = 685.5

const cir1 = 2 * Math.PI * r1
const cir2 = 2 * Math.PI * r2

const Lines: FC = () => {
  return (
    <svg width='830' height='835' viewBox='0 0 830 835' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <Transition
        enterFrom='-rotate-[104deg]'
        enterTo='rotate-0'
        className='transition-all duration-2s translate-x-[670.5px] translate-y-[5px]' r={r1} stroke='#EE2323' strokeDasharray={`${cir1 / 2},${cir1}`} strokeWidth='2'
        as='circle'
        appear={true}
        show={true}
      />
      <Transition
        enterFrom='-rotate-[104deg]'
        enterTo='rotate-0'
        className='transition-all duration-2s delay-200 translate-x-[765px] translate-y-[75px]' r={r2} stroke='#EE2323' strokeDasharray={`${cir2 / 1.94},${cir2}`} strokeOpacity='0.5'
        as='circle'
        appear={true}
        show={true}
      />
    </svg>
  )
}

export default Lines