import React, { FC } from 'react'
import Image from 'next/image'
import lightning from 'public/graphics/main/features/lightning.png'
import orb from 'public/graphics/main/features/orb.png'
import network from 'public/graphics/main/features/network.png'
import chart from 'public/graphics/main/features/chart.png'
import compass from 'public/graphics/main/features/compass.png'

const features = [
    {
        title: 'Быстрое оформление',
        image: lightning
    },
    {
        title: 'Прозрачная сделка',
        image: orb
    },
    {
        title: 'Полностью онлайн',
        image: network
    },
    {
        title: 'Экономия на налогах',
        image: chart
    },
    {
        title: 'Работаем по всей России',
        image: compass
    },
]

const Features: FC = () => {
    return (
        <ol className='md:flex justify-between text-left md:text-center font-display text-h400 font-medium'>
            {features.map(f =>
                <li className='md:w-34 flex md:block' key={f.title}>
                    <div className='w-20 md:w-auto flex-none md:flex-auto mr-5 md:mr-0'>
                        <Image src={f.image} alt={f.title} layout='responsive' />
                    </div>
                    <div className='pt-4 pr-4 md:pr-0'>{f.title}</div>
                </li>
            )}
        </ol>
    )
}

export default Features