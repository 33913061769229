import type { NextPage } from 'next'
import { useState } from 'react'
import { applicationFormOpen } from 'src/store'
import Layout from '@components/Layout'

import Features from '@components/main/Features'
import ProductCards from '@components/main/Cards'
import Steps from '@components/main/Steps'
import Lines from '@components/main/Lines'
import dynamic from 'next/dynamic'
import { STEPS } from '@utils/constants'
import { useIntl } from 'react-intl'
import { maxLeasingAmount } from '@src/config'

const Calculator = dynamic(() => import('@components/main/Calculator'))
const Carousel = dynamic(() => import('@components/main/Carousel'))
const Comparison = dynamic(() => import('@components/main/Comparison'))
const Form = dynamic(() => import('@components/main/Form'))
const FormSuccess = dynamic(() => import('@components/main/FormSuccess'))
const Partners = dynamic(() => import('@components/main/Partners'))

const Home: NextPage = () => {
  const [formDone, setFormDone] = useState(false)
  const { formatNumber } = useIntl()

  return (
    <Layout>
      <div className='overflow-x-hidden relative'>
        <div className='absolute pointer-events-none top-0 right-0 origin-top-right scale-50 md:scale-60 lg:scale-80 xl:scale-100'>
          <Lines />
        </div>
        <main className='relative overflow-hidden'>
          <section className='container container-xl px-6 xl:px-0 relative pt-80 md:pt-43 pb-5 md:pb-44'>
            <div className='absolute pointer-events-none top-0 right-1/2 lg:right-0 md:right-0 md:translate-y-1/4 lg:translate-y-0 translate-x-1/2 md:translate-x-0'>
              <Carousel />
            </div>
            <div className='relative z-10'>
              <h1 className='font-display text-h300 lg:text-h100 font-medium mb-6 fera-dot'>
                Масштабируйтесь — <br />
                мы оплатим
              </h1>
              <p className='text-grayscale-150 text-p350 lg:text-p100 lg:w-100 md:w-80 mb-8'>
                Сдадим все виды оборудования от 100 000 до{' '}
                {formatNumber(maxLeasingAmount)} рублей в короткий срок без
                посещения офиса.{' '}
              </p>
              <button
                onClick={() => applicationFormOpen.set(true)}
                className='px-8 h-11 bg-red-100 hover:bg-red-50 active:bg-red-150 rounded-full text-white-0 text-p200 font-semibold leading-full w-full md:w-auto'
              >
                Подать заявку
              </button>
            </div>
          </section>
          <section className='container container-xl px-6 xl:px-0'>
            <div className='text-center md:text-left lg:flex md:grid md:grid-cols-2 xl:gap-6 lg:justify-center lg:rounded-xl lg:bg-white-0 lg:space-x-10 mb-12 lg:mb-10 lg:py-10 lg:border-1 lg:border-grayscale-400 relative z-10'>
              <div className='mb-6 lg:mb-0 text-center'>
                <div className='text-red-100 font-display mb-1 md:mb-2 text-h300 md:text-h200 font-medium'>
                  от 0%
                </div>
                <div className='text-p350 md:text-p100 text-grayscale-150'>
                  авансовый платёж
                </div>
              </div>
              <div className='mb-6 lg:mb-0 text-center'>
                <div className='text-red-100 font-display mb-1 md:mb-2 text-h300 md:text-h200 font-medium'>
                  от 100 000 ₽
                </div>
                <div className='text-p350 md:text-p100 text-grayscale-150'>
                  сумма сделки
                </div>
              </div>
              <div className='mb-6 lg:mb-0 text-center'>
                <div className='text-red-100 font-display mb-1 md:mb-2 text-h300 md:text-h200 font-medium'>
                  от 6 мес.
                </div>
                <div className='text-p350 md:text-p100 text-grayscale-150'>
                  срок финансирования
                </div>
              </div>
              <div className='mb-6 lg:mb-0 text-center'>
                <div className='text-red-100 font-display mb-1 md:mb-2 text-h300 md:text-h200 font-medium'>
                  от 3-х часов
                </div>
                <div className='text-p350 md:text-p100 text-grayscale-150'>
                  одобрение заявки
                </div>
              </div>
            </div>
          </section>
          <section className='container container-xl px-0 xl:px-0 lg:px-6 mb-10 md:mb-20'>
            <ProductCards />
          </section>
          <section className='container container-xl px-6 xl:px-0 mb-12 md:mb-30'>
            <h1 className='font-display text-h300 md:text-h100 font-medium mb-6 fera-dot'>
              Лизинг — это решение
            </h1>
            <p className='text-grayscale-150 text-p350 md:text-p100 md:w-140 mb-8'>
              Лизинг – это финансовая аренда, когда мы покупаем у поставщика
              любое оборудование и сдаём вам за фиксированный ежемесячный
              платёж, а в конце срока оборудование переходит в вашу
              собственность. График платежей формируется исходя из ваших
              возможностей ещё до момента заключения договора.
            </p>
            <Features />
          </section>
          <section className='container container-xl mb-7 md:mb-20'>
            <h1 className='font-display text-h300 md:text-h100 font-medium mb-7 md:mb-14 px-6 xl:px-0 fera-dot'>
              Схема лизинговой сделки
            </h1>
            <Steps steps={STEPS} />
          </section>
          <section className='container container-xl px-6 xl:px-0 md:mb-30'>
            <h1 className='font-display text-h300 md:text-h100 font-medium mb-10 fera-dot'>
              Партнёры
            </h1>
            <Partners />
          </section>
          <div className='h-12 md:h-20 bg-gradient-to-b from-grayscale-450' />
          <section className='container container-xl px-6 xl:px-0 mb-7 md:mb-10'>
            <h1 className='font-display text-h300 md:text-h100 font-medium md:mb-10 mb-7 fera-dot'>
              Посчитайте сами и сравните альтернативы
            </h1>
            <Calculator />
          </section>
          <section className='container container-xl mb-7 md:mb-20'>
            <Comparison />
          </section>
          <section className='container container-xl px-0 lg:px-6 xl:px-0'>
            <div className='p-6 lg:p-10 lg:mb-20 bg-white-0 lg:rounded-xl relative z-10 grid md:flex md:justify-between border-grayscale-400 border-1 shadow-xl'>
              <div className='md:flex contents flex-col mr-20 lg:mr-33'>
                <h2 className='font-display lg:text-h200 text-h400 font-medium mb-5'>
                  Остались вопросы?
                  <br /> Оставьте заявку
                </h2>
                <p className='lg:text-p100 text-p350 text-grayscale-150 lg:mb-0 mb-6'>
                  Мы с радостью ответим на ваши вопросы. Заполните форму и мы
                  вам перезвоним.
                </p>
                <p className='text-p450 mt-auto text-grayscale-200'>
                  Отправляя форму, вы даете{' '}
                  <a
                    href='/docs/personal-data-agreement.pdf'
                    target='_blank'
                    rel='noreferrer'
                    className='text-red-100 hover:underline'
                  >
                    согласие на обработку персональных данных
                  </a>
                  , указанных вами в заявке, в соответствии с требованиями
                  Федерального закона № 152-ФЗ от 27.07.2006 «О персональных
                  данных».
                </p>
              </div>
              <div className='lg:w-80 md:w-70 flex-none row-start-3 row-span-1 md:mb-0 mb-6'>
                {formDone ? (
                  <div className='h-65 flex items-center justify-center'>
                    <FormSuccess />
                  </div>
                ) : (
                  <Form source='main' onSuccess={() => setFormDone(true)} />
                )}
              </div>
            </div>
          </section>
          <svg
            className='absolute bottom-0 left-0 pointer-events-none hidden lg:block'
            width='728'
            height='649'
            viewBox='0 0 728 649'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <circle
              cx='-101.5'
              cy='670.5'
              r='669.5'
              stroke='#EE2323'
              strokeWidth='2'
            />
            <circle
              cx='42'
              cy='771'
              r='685.5'
              stroke='#EE2323'
              strokeOpacity='0.5'
            />
          </svg>
          {/* <section className='container container-xl pt-20'>
          <p className='mb-4 font-body text-p100'>P100: Алая вспышка осветила силуэт зазубренного крыла</p>
          <p className='mb-4 font-body text-p200 font-semibold'>P200: Алая вспышка осветила силуэт зазубренного крыла</p>
          <p className='mb-4 font-body text-p250'>P250: Алая вспышка осветила силуэт зазубренного крыла</p>
          <p className='mb-4 font-body text-p300 font-semibold'>P300: Алая вспышка осветила силуэт зазубренного крыла</p>
          <p className='mb-4 font-body text-p350'>P350: Алая вспышка осветила силуэт зазубренного крыла</p>
          <p className='mb-4 font-body text-p400 font-medium'>P400: Алая вспышка осветила силуэт зазубренного крыла</p>
          <p className='mb-4 font-body text-p450'>P450: Алая вспышка осветила силуэт зазубренного крыла</p>
          <br />
          <p className='mb-4 font-display text-h100 font-medium'>H100: Алая вспышка осветила силуэт зазубренного крыла</p>
          <p className='mb-4 font-display text-h200 font-medium'>H200: Алая вспышка осветила силуэт зазубренного крыла</p>
          <p className='mb-4 font-display text-h300 font-medium'>H300: Алая вспышка осветила силуэт зазубренного крыла</p>
          <p className='mb-4 font-display text-h350'>H350: Алая вспышка осветила силуэт зазубренного крыла</p>
          <p className='mb-4 font-display text-h400 font-medium'>H400: Алая вспышка осветила силуэт зазубренного крыла</p>
          <p className='mb-4 font-display text-h450 font-medium'>H450: Алая вспышка осветила силуэт зазубренного крыла</p>
        </section> */}
        </main>
      </div>
    </Layout>
  )
}

export default Home
